/**
 * import
 *
 * @version 0.1 (2021.05.14 : usayama)
 */
import React from 'react'
// conf, action
// components
// containers
import LayoutContainer from '@containers/LayoutContainer'
import TextBasePageContainer from '@containers/TextBasePageContainer'
// helper
// style, img, elm
// const

/**
 * const
 *
 * @version 0.1 (2021.05.14 : usayama)
 */
const Terms = () => (
  <LayoutContainer>
    <TextBasePageContainer />
  </LayoutContainer>
)

/**
 * export
 *
 * @version 0.1 (2021.05.14 : usayama)
 */
export default Terms